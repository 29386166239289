import { useState } from "react";
import LoginForm from "./components/Login";
import { genearateBlogsReport, signIn } from "./lib/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { Button, Flex, Text } from "@chakra-ui/react";
import GenerateReport from "./components/GenearateReport";

function App() {
  const [user, setUser] = useState();
  const [reportData, setReportData] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const loginBtnClickHandler = async ({ email, password }) => {
    try {
      const user = await signIn(email, password);
      setUser(user);
    } catch (err) {
      alert("invalid credentials");
    }
  };

  const generateReport = async () => {
    const sd = dayjs(startDate).format("MM-DD-YYYY");
    const ed = dayjs(endDate).format("MM-DD-YYYY");
    try {
      const response = await genearateBlogsReport({
        startDate: sd,
        endDate: ed,
        authToken: user?.jwt,
      });

      setReportData(response);
    } catch (err) {
      if (err?.response?.status === 403) {
        alert("You don't have access!");
      } else {
        alert("Something went wrongs!");
      }
    }
  };

  return (
    <div className="App" style={{ padding: 20 }}>
      {!user && <LoginForm signIn={loginBtnClickHandler} />}

      {user && (
        <Flex align="center" justify="center" gap={10}>
          <div>
            <Text py=".5rem" px="1rem">
              Select Start Date
            </Text>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="datepicker"
              dateFormat="dd-MMMM-yyyy"
            />
          </div>

          <div>
            <Text py=".5rem" px="1rem">
              Select End Date
            </Text>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="datepicker"
              dateFormat="dd-MMMM-yyyy"
            />
          </div>

          <Button variant="primary" onClick={generateReport}>
            Generate Data
          </Button>
        </Flex>
      )}

      {reportData && <GenerateReport reportData={reportData} />}
    </div>
  );
}

export default App;
