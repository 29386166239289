import { Center } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";

export const Logo = () => (
  <Center>
    <Image
      boxSize={14}
      src="https://mtech-cms-assets.s3.ap-south-1.amazonaws.com/logo_cf795f9967.png"
    />
  </Center>
);
