// import { useRef } from "react";

// const LoginForm = ({ signIn }) => {
//   const emailRef = useRef();
//   const passwordRef = useRef();

//   const loginClickHandler = () => {
//     const email = emailRef.current?.value;
//     const password = passwordRef.current?.value;
//     signIn({ email, password });
//   };

//   return (
//     <div className="login-form">
//       <input type="text" placeholder="enter email" ref={emailRef} />
//       <input type="text" placeholder="enter password" ref={passwordRef} />
//       <button onClick={loginClickHandler}>login</button>
//     </div>
//   );
// };

// export default LoginForm;

import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import { Logo } from "./Logo";
import { PasswordField } from "./PasswordField";

const LoginForm = ({ signIn }) => {
  const emailRef = React.useRef();
  const passwordRef = React.useRef();

  const loginClickHandler = () => {
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;
    signIn({ email, password });
  };

  return (
    <Container
      maxW="lg"
      py={{ base: "12", md: "24" }}
      px={{ base: "0", sm: "8" }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <Logo />
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
              Log in to your account
            </Heading>
          </Stack>
        </Stack>
        <Box
          py={{ base: "0", sm: "8" }}
          px={{ base: "4", sm: "10" }}
          bg={useBreakpointValue({ base: "transparent", sm: "bg-surface" })}
          boxShadow={{ base: "none", sm: useColorModeValue("md", "md-dark") }}
          borderRadius={{ base: "none", sm: "xl" }}
        >
          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input id="email" type="email" ref={emailRef} />
              </FormControl>
              <PasswordField ref={passwordRef} />
            </Stack>

            <Stack spacing="6">
              <Button onClick={loginClickHandler} variant="primary">
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};

export default LoginForm;
