import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api`;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

// identifier can be email or username

export const signIn = async (identifier, password) => {
  try {
    const { data } = await axiosInstance.post("/auth/local", {
      identifier: identifier,
      password: password,
    });
    return data;
  } catch (err) {
    console.log("Err", err);
    throw err;
  }
};

export const genearateBlogsReport = async ({
  startDate,
  endDate,
  authToken,
}) => {
  try {
    const { data } = await axiosInstance.get(
      `/blog-word-count?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return data;
  } catch (err) {
    throw err;
  }
};
