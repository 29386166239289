import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  Text,
  Box,
} from "@chakra-ui/react";
import dayjs from "dayjs";

const GenerateReport = ({ reportData }) => {
  if (reportData?.length === 0) {
    return <Text>There are no data</Text>;
  }

  return (
    <Box overflow="auto">
      <Table variant="simple" mt={20}>
        <Thead>
          <Tr>
            <Th>Blog Title</Th>
            <Th>Total Title's Words</Th>
            <Th>Total Description's Words</Th>
            <Th>Total Content's Words</Th>
            <Th>Total = Title + Descp. + Content</Th>
            <Th>Published Date</Th>
            <Th>Author Name</Th>
            <Th>Blog Link</Th>
          </Tr>
        </Thead>
        <Tbody>
          {reportData?.map(
            (
              { slug, title, description, content, publishedAt, authorName },
              idx
            ) => {
              const sum =
                title.total_number_of_word +
                description.total_number_of_word +
                content.total_number_of_word;

              return (
                <Tr key={idx}>
                  <Td textTransform="capitalize">{title.name}</Td>
                  <Td>{title.total_number_of_word}</Td>
                  <Td>{description.total_number_of_word}</Td>
                  <Td>{content.total_number_of_word}</Td>
                  <Td>{sum}</Td>
                  <Td>{dayjs(publishedAt).format("DD/MM/YYYY")}</Td>
                  <Td textTransform="capitalize">
                    {authorName === "undefined undefined" ? "NA" : authorName}
                  </Td>
                  <Td>
                    <Link
                      color="blue.400"
                      textDecoration="underline"
                      href={`https://mtechzilla.com//blog/${slug.name}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Blog link
                    </Link>
                  </Td>
                </Tr>
              );
            }
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default GenerateReport;
